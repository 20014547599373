import React, { useEffect, useState } from "react";

import Post from "./post.js"

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://social_media_worker.zzatuchn.workers.dev/posts"
      );
      const postsResp = await resp.json();
      setPosts(postsResp.posts);
    };

    getPosts();
  }, []);

  return (
    <div>
      <h2>Posts</h2>
      {posts.map((post) => (
        <div key={post.post_num}>
          <Post username={post.username} title={post.title} content={post.content}></Post>
        </div>
      ))}
    </div>
  );
};

export default Posts;