import Posts from "./components/posts.js"
import Form from "./components/form"

import './App.css';

function App() {
  return (
    <div>
      <div className="header">
        <img src="logo.png" alt="logo" width="160" height="80"></img>
        <h1 style={{marginLeft: 20, marginTop: 20, fontSize: 50}}>Cloudfare Chat</h1>
      </div>
      <Form></Form>
      <Posts></Posts>
    </div>
  );
}

export default App;
