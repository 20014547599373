import React from "react";

class Form extends React.Component {

    constructor() {
        super();
        this.state = {
            username: "Anonymous",
            title: "Untitle Post",
            content: "Lorem ipsum",
        }

        this.handleChange = this.handleChange.bind(this);
        this.addPost = this.addPost.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name] : value
        });

    }

    async addPost(event) {
        event.preventDefault();
        const username = this.state.username;
        const title = this.state.title;
        const content = this.state.content;
        fetch("https://social_media_worker.zzatuchn.workers.dev/posts", {
            method: "post",
            mode: "cors",
            body: JSON.stringify({
                username: username,
                title: title,
                content: content
            })
        }).then(() => {
            console.log("Post successful")
            window.location.reload();
        });
    }

    render() {
        return (
            <div className="addPost">
                <h2>Add post</h2>
                <form onSubmit={this.addPost}>
                    <label htmlFor="username">Username:</label><br></br>
                    <input required="required" name="username" onChange={this.handleChange}></input><br/><br/>
                    <label htmlFor="title">Post title:</label><br></br>
                    <input required="required" name="title" onChange={this.handleChange}></input><br/><br/>
                    <label htmlFor="content">Content:</label><br></br>
                    <textarea required="required" name="content" onChange={this.handleChange}
                        rows="7" cols="40" white-space="pre-wrap" style={{resize:"none"}}></textarea><br/><br/>
                    <input type="submit" value="Submit"></input><br></br>
                    <label>Note: posts will take a few moments to be added to the KV.</label>
                </form>
            </div>
        );
    }
}

export default Form;