import React from "react";

const Post = data => {
    return (
        <div className="post">
            <p><b>{data.username}</b></p>
            <p><u>{data.title}</u></p>
            <p>{data.content}</p>
        </div>
    );
}

export default Post;